
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import {
  IUserLog,
  UserLogFilter,
  UserLogModel
} from "@/apis/interface/IUserLog";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const apiUserLog = inject("apiUserLog") as IUserLog;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: UserLogFilter = reactive({
      adminId: null,
      option: "",
      message: "",
      level: ""
    });

    let clearFilter = () => {
      filter.adminId = null;
      filter.option = "";
      filter.message = "";
      filter.level = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<UserLogModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiUserLog.getList(pageParams, filter);
      const pager = resp as RespBodyPager<UserLogModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let deleteRow = async (id: number) => {
      let result = (await apiUserLog.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );

    let tableHeight = ref(0);

    return {
      loading,
      pageParams,
      filter,
      dataList,
      tableHeight,
      loadData,
      deleteRow,
      clearFilter
    };
  }
});
